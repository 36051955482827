.mantine-Calendar-calendarHeader {
  background-color: #6c757d;
  width: 99%;
  border-radius: 1% 1% 0 0;
  border-bottom: solid 2px #6c757d;
  padding: auto;
}

.mantine-Calendar-calendarHeader .mantine-UnstyledButton-root {
  color: #fff !important;
}

.mantine-Calendar-calendarHeader .mantine-UnstyledButton-root:hover {
  background-color: #6c757d;
}

.mantine-Calendar-month .mantine-Calendar-weekday {
  color: black !important;
}

.react-calendar__tile {
  border-radius: 20%;
  background-color: #fff;
}

.mantine-Calendar-cell button:not([disabled]):not([data-selected]) {
  border: 1px solid #ced4da;
  background-color: #fff;
}

.mantine-Calendar-cell button:not([disabled]):is([data-selected]) {
  background-color: #6c757d;
}

.mantine-Calendar-cell button:not([disabled]):hover {
  background-color: #ced4da;
}

#dropdown-basic {
  background-color: white;
  color: black;
}

.calender-form {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 5%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 5%;
  width: 55%;
  min-width: 500px;
}

.timeslot {
  border: solid 1px;
  margin: 2%;
  padding: 3%;
}

.scheduler-container {
  height: 300px;
}

@media only screen and (max-width: 1200px) {
  .react-calendar {
    width: auto;
  }
}

.Form {
  border: 1px solid;
  border-radius: 1%;
  padding: 1%;
  margin: 1%;
}

.borderline {
  border: 1px solid;
  padding: 1%;
  margin: 1%;
}

.alert {
  position: fixed;
  top: 0;
  margin: 20px;
  z-index: 1000;
}

.icon-border,
.icon-border:hover {
  border: 1px solid #000 !important;
  padding: 3px !important;
}
